import { inject, Injectable } from '@angular/core';
import { CasesBackendService } from '@dev-fast/backend-services';
import type { EPhaseClass, IP2pDepositingItem } from '@dev-fast/types';
import {
  EBackgroundClass,
  EDiscountClass,
  EWearType,
  MAX_ACCEPTABLE_RECOMMENDED_PRICE,
  RProductCardBackgroundClass,
  RProductCardPhaseClass,
} from '@dev-fast/types';

import { toShortRevisionWear } from '@app/shared/utils';
import { transformSkinUrl, transformStickerUrl } from '@app/shared/utils/skin-url';

@Injectable()
export class ExchangeProductCardService {
  readonly #casesBackendService = inject(CasesBackendService);

  getItemImageSrc(item: IP2pDepositingItem): string[] {
    const { items = [] } = item;

    return items.map((marketItem) => {
      return transformSkinUrl(marketItem.baseItem.icon);
    });
  }

  getDiscount(item: IP2pDepositingItem): number {
    const { recommendedPrice, sellerPrice } = item;
    if (recommendedPrice && sellerPrice && sellerPrice < MAX_ACCEPTABLE_RECOMMENDED_PRICE) {
      return Math.round((sellerPrice / recommendedPrice - 1) * 100);
    }

    return 0;
  }

  getWearType(item: IP2pDepositingItem): EWearType[] {
    if (!item.items || item.items.length > 1) {
      return [];
    }
    const [firstMarketItem] = item.items;
    const { exterior, statTrak, isSouvenir } = firstMarketItem.baseItem;
    const wearArr: EWearType[] = [];

    if (isSouvenir) {
      wearArr.push(EWearType.SV);
    } else if (statTrak) {
      wearArr.push(EWearType.ST);
    }

    if (exterior) {
      wearArr.push(toShortRevisionWear(exterior) as EWearType);
    }

    return wearArr;
  }

  getItemWear(item: IP2pDepositingItem): number {
    const NUMBERS_AFTER_DOT = 5;

    if (!item.items || item.items.length > 1) {
      return 0;
    }

    const [firstMarketItem] = item.items;
    const { float } = firstMarketItem;

    return parseFloat((float ?? 0).toFixed(NUMBERS_AFTER_DOT));
  }

  getItemPhase(item: IP2pDepositingItem): string | null | undefined {
    if (!item.items || item.items.length > 1) {
      return null;
    }

    const [firstMarketItem] = item.items;
    const { phase } = firstMarketItem.baseItem;

    return phase;
  }

  getItemType(item: IP2pDepositingItem): string {
    if (!item.items) {
      return '';
    }
    const [firstMarketItem] = item.items;
    const { name, type, shortName, skin } = firstMarketItem.baseItem;

    if (!name || !skin) {
      return '';
    }

    return type === 'weapon' && shortName ? shortName : name.split('|')[0];
  }

  getItemName(item: IP2pDepositingItem): string {
    if (!item.items) {
      return '';
    }

    const [firstMarketItem] = item.items;
    const { name, type, shortName, skin } = firstMarketItem.baseItem;

    return skin ? skin : type === 'weapon' && shortName ? shortName : name;
  }

  getItemBadges(item: IP2pDepositingItem): string[] {
    if (!item.items) {
      return [];
    }
    const [firstMarketItem] = item.items;
    const { stickers = [] } = firstMarketItem;

    return stickers.map((sticker) => transformStickerUrl(sticker.icon));
  }

  getItemBackgroundClass(item: IP2pDepositingItem): EBackgroundClass {
    if (!item.items) {
      return EBackgroundClass.CONSUMER;
    }

    const [firstMarketItem] = item.items;
    const { rarity, color } = firstMarketItem.baseItem;
    const classKey = (rarity ?? color).toLowerCase();
    const palette = RProductCardBackgroundClass[classKey] ?? EBackgroundClass.CONSUMER;

    return palette;
  }

  getItemDiscountClass(item: IP2pDepositingItem): EDiscountClass {
    if (!item.items) {
      return EDiscountClass.YELLOW;
    }

    const discount = this.getDiscount(item);

    switch (true) {
      case discount > 30:
        return EDiscountClass.RED;
      case discount > 10:
        return EDiscountClass.ORANGE;
      case discount < -3:
        return EDiscountClass.GREEN;

      default:
        return EDiscountClass.YELLOW;
    }
  }

  getItemPhaseClass(item: IP2pDepositingItem): EPhaseClass | '' {
    if (!item.items) {
      return '';
    }

    const [firstMarketItem] = item.items;
    const { phase } = firstMarketItem.baseItem;
    const classKey = (phase ?? '').toLowerCase();

    return RProductCardPhaseClass[classKey] ?? '';
  }
}
